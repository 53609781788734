<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Checklists</h4>
                        </b-col>
                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" squared @click="addNewChecklistModal()">Add New</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Checklist Name</label>
                            <b-form-input v-model="search.description"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <label>Date Created From</label>
                            <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
                        </b-col>
                        <b-col cols="4">
                            <label>Date Created To</label>
                            <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Type</label>
                            <b-form-select v-model="search.typeId" :disabled="state === 'loading'">
                                <b-form-select-option v-for="(item, index) in checklistTypes" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <hr class="mx-3" />
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="red" squared class="mr-2" @click="clearFilter()">Cancel</b-button>
                            <b-button variant="primary" squared @click="searchResults()">Search</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                                :busy="tableData.isLoading" @row-clicked="openCard"
                        :per-page="tableData.resultsPerPage"
                        :current-page="tableData.currentPage" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>

                                <template #cell(disabled)="row">
                                    <b-row align-v="center">
                                        <b-badge :variant="row.item.disabled ? 'danger' : 'success'">{{row.item.disabled ? 'Disabled' : 'Enabled'}}</b-badge>
                                    </b-row>
                                </template>

                                <template #cell(fkTicketId)="row">
                                    <b-row align-v="center">
                                        <span class="mr-auto">{{padResult(row.item.fkTicketId)}}</span>
                                    </b-row>
                                </template>
                                
                                <template #cell(checkListDate)="row">
                                    <b-row align-v="center">
                                        <span class="mr-auto">{{row.item.checkListDate | dateTimeFilter}}</span>
                                    </b-row>
                                </template>

                                
                                <template #cell(actions)="row">
                                    <b-row align-v="center" align-h="end">
                                        <b-button @click="openCard(row.item)" class="btn-icon" size="sm">
                                            <b-icon-chevron-right></b-icon-chevron-right>
                                        </b-button>
                                    </b-row>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row align-h="center" >
                            <b-pagination
                            v-model="tableData.currentPage"
                            :total-rows="rows"
                            :per-page="tableData.perPage"
                            ></b-pagination>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        
        <b-modal id="addNew-modal" hide-footer no-close-on-backdrop @close="closeModal()">
            <b-row>
                <b-col cols="12">
                    <label>Procedure Name</label>
                    <b-form-input v-model="newChecklistValues.description"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Procedure Number</label>
                    <b-form-input v-model="newChecklistValues.procedureNumber"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right mt-3">
                    <b-button variant="red" @click="hideModal" squared class="mr-2">Cancel</b-button>
                    <b-button variant="primary" @click="addNewChecklist()" squared>Done</b-button>
                </b-col>
            </b-row>
            <div class="d-block"></div>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    name: 'Logger',
    data: () => ({
        state: 'initiliazing',
        newQuoteTicket: null,
        checklistTypes: [],
        statusList: [],
        newChecklistValues: {
            checkListId: 0,
            categories: [],
            description: null,
            procedureNumber: null,
            disabled: true
        },
        search:{
            description: null,
            dateCreatedFrom: null,
            dateCreatedTo: null,
            typeId: null
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Status',
                    key: 'disabled',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Procedure Number',
                    key: 'procedureNumber',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Checklist Name',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Type',
                    key: 'checkListType',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Date Created',
                    key: 'checkListDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Created By',
                    key: 'createdByUser',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        }
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'Checklists'
            },
            {
                text: 'Search',
                active: true
            }
        ])
        this.getChecklistTypes()
        .then((res) => {
            this.searchResults()
            this.checklistTypes = res.data
        })
        .catch(() => {
            this.state = 'show'
        })
    
    },
    methods:{
        ...mapActions(['searchChecklists', 'createChecklist', 'getChecklistTypes']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        addNewChecklistModal(){
            this.$root.$emit('bv::show::modal', 'addNew-modal', '#btnShow')
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'addNew-modal', '#btnShow')
        },
        openCard(item){
            this.$store.commit('setSelectedChecklist', item)
            this.$router.push({path: '/checklist/manage'})
        },
        addNewChecklist(){
            this.$store.commit('setChecklistCreateRequest', this.newChecklistValues)
            this.createChecklist()
            .then(() => {
                this.newChecklistValues = {
                    checkListId: 0,
                    categories: [],
                    description: null,
                    procedureNumber: null
                }
                this.hideModal()
                this.$router.push({path: '/checklist/manage'})
            })
            .catch(() => {
                this.hideModal()
            })
        },
        clearFilter(){
            this.search = {
                description: null,
                dateCreatedFrom: null,
                dateCreatedTo: null,
                typeId: null
            }
        },
        searchResults(){
            this.tableData.isLoading = true
            
            let request = []
            if(this.search.description !== null){
                request.push({"key":"description","value": this.search.description})
            }
            if(this.search.dateCreatedFrom !== null){
                request.push({"key":"createdDateFrom","value": this.search.dateCreatedFrom})
            }
            if(this.search.dateCreatedTo !== null){
                request.push({"key":"createdDateTo","value": this.search.dateCreatedTo})
            }
            if(this.search.typeId !== null){
                request.push({"key":"fkcheckListTypeid","value": this.search.typeId})
            }

            this.$store.commit('setChecklistSearchRequest', request)
            this.searchChecklists()
            .then((res) => {
                this.tableData.dataSource = res.data
                this.tableData.isLoading = false
            })
            .catch(() => {
                this.tableData.isLoading = false
            })
        }
    },
    computed:{
        rows()
        {
            return this.tableData.dataSource.length
        }
    }
}
</script>
